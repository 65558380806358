<template>
	<w-layout fill-height overflow-hidden>
		<router-view></router-view>
	</w-layout>
</template>

<script>
import AuthModuleGuard from '@/mixins/ModulesGuards/Auth/AuthModuleGuard'

export default {
	name: 'AnonymousPages',
	mixins: [AuthModuleGuard]
}
</script>
